import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import { db } from '../firebase/firebase';
import { nanoid  } from 'nanoid';
import { FiPlus } from "react-icons/fi";
import { AuthContext } from '../firebase/authContext';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  popover: {

  },
    typography: {
    margin: theme.spacing.unit * 2,
  },
  textField: {
    width: '400px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: '8px',
    fontSize: '7px'
  },
  confirmButton: {
    width: '400px',
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid #00FF00',
    color: '#00FF00',
    height: 50,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: '8px',
    marginBottom: '11px',
  },
  iconButton: {
    color: '#FFF',
    fontSize: 19
  },
  /* customTextField: {
    height: '7px',
    padding: '6 10px',
    fontSize: '0.7em'
  } */
});

class AddTrackItem extends React.Component {

  constructor() {
    super()
    this.saveTrack = this.saveTrack.bind(this);

    this.state = {
      anchorEl: null,
      description: "",
      uri: "",
      startAt: ""
    };
  }

 saveTrack() {

  var self = this;
  
  var playlist = Object.assign({}, this.props.playlist);

  var newLink = {
    id: nanoid(),
    description: this.state.description,
    uri: this.state.uri,
    startAt: parseInt(this.state.startAt),
    volume: 0.8
  }

  playlist.sections[this.props.sectionIndex].links.push(newLink);

  let userId = this.context.user.id;

  var docRef =  db.collection("users").doc(userId)
  .collection('playlists').doc(this.props.playlist.id); 

  docRef.update({
    sections: playlist.sections
  }).then(function() {
    self.handleClose();
}).catch(function(error) {
    console.log("Error getting document:", error);
});

 }
 
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  static contextType = AuthContext;

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
   {/*     <Button
          aria-owns={open ? 'simple-popper' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          Lägg till låt
        </Button> */}
          <FiPlus className={classes.iconButton} onClick={this.handleClick}/>
        <Popover
        PaperProps={{
          style: {
            backgroundColor: '#080808',
            boxShadow: 'none',
          },
        }}
        className={classes.popover}
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        <div>
           <TextField
          id="outlined-name"
          label="Beskrivning"
          className={classes.textField}
          style={{marginTop: '11px'}}
          value={this.state.description}
          onChange={this.handleChange('description')}
          margin="normal"
          variant="outlined"
       /*    inputProps={{ className: classes.customTextField }}
          InputLabelProps={{ fontSize: '6px' }} */
        />
        </div>
        <div>
            <TextField
          id="outlined-name"
          label="Uri"
          className={classes.textField}
          value={this.state.uri}
          onChange={this.handleChange('uri')}
          margin="normal"
          variant="outlined"
         /*  inputProps={{ className: classes.customTextField }}
          InputLabelProps={{
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              fontSize: '1.9em',
              color: 'white'
            } }} */
        />
        </div>
        <div>
          <TextField
          id="outlined-name"
          label="Startposition (sekunder)"
          className={classes.textField}
          value={this.state.startAt}
          onChange={this.handleChange('startAt')}
          margin="normal"
          variant="outlined"
         /*  inputProps={{ className: classes.customTextField }} */
        />
        </div>
        <Button variant="outlined"
        color="primary"
        className={classes.confirmButton}
        onClick={this.saveTrack}>
        Skapa länk
      </Button>
        </Popover>
      </React.Fragment>
    );
  }
}

AddTrackItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddTrackItem);