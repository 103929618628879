import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from '../firebase/authContext';
import ProgressIndicator from './ProgressIndicator';


const ProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ authUser, isLoading }) => (
      <Route
        render={ props =>
          authUser ? <Component {...props} /> : 
          <div>{isLoading === true ? <ProgressIndicator/> : 
          <Redirect to="/SignIn" />}</div>
        }
        {...rest}
      />
    )}
  </AuthConsumer>
)

export default ProtectedRoute;