import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select'
import { CompactPicker } from 'react-color'
import { desc } from '../common/helpers';

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
    },
    textField: {
        width: '400px',
        marginRight: theme.spacing.unit,
        marginBottom: '25px',
    },
    deleteBtn: { border: "1px solid red" },
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    button: {
        background: 'transparent',
        borderRadius: 3,
        border: '1px solid yellow',
        color: 'yellow',
        height: 33,
        marginRight: 3,
        marginLeft: 3,
        marginBottom: 7,
        pointer: 'cursor',
        overflow: 'hidden !important',
        width: 140,
        whiteSpace: 'nowrap',
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: '0.75rem'
      },
});

const darkTheme = {
    control: styles => ({
        ...styles,
        backgroundColor: 'black',
        color: 'white',
        height: '50px',
        maxHeight: '50px',
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected
                ? 'darkgray'
                : isFocused
                    ? 'gray'
                    : null,
            color: 'white',
        };
    },
    singleValue: styles => ({ ...styles, color: 'white' }),
    menu: styles => ({ ...styles, backgroundColor: 'black' }),
};

class EditTrackDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sliderVolume: 0,
            bgColor: "#000",
            textColor: "#FFFF00",
            borderColor: "#FFFF00"
        };

    }

    getCurrentSection = (props) => {
        if (props.playlist != null && props.track != null) {
            props.playlist.sections.forEach(section => {
                let indexOfTrack = section.links.findIndex(s => s.id === props.track.id);
                if (indexOfTrack > -1) {
                    return section;
                }
            });
        }
        return null;
    }

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    handleChangeDescription = name => event => {
        this.props.onEditDescription(event.target.value);
    };

    handleChangeUri = name => event => {
        this.props.onEditUri(event.target.value);
    };

    handleChangeStartposition = name => event => {
        let value = event.target.value;
        if (event.target.value === '') {
            value = 0;
        }
        const startAt = parseInt(value);
        this.props.onEditStartPosition(startAt);
    };

    handleSave = () => {
        this.props.onSave();
    };

    handleClose = () => {
        this.props.onClose();
    };

    handleDeleteTrack = () => {
        this.props.onDeleteTrack();
    };


    handleVolumeChange = (value) => {
        this.props.onVolumeChange(value);
    }

    handleChangeSortOrder = (value) => {
        this.props.onSortOrderChange(value.value);
    }

    handleChangeBackgroundColor = (color) => {
        this.setState({ bgColor: color.hex });
        this.props.onBgColorChange(color.hex);
    };

    handleChangeTextColor = (color) => {
        this.setState({ textColor: color.hex });
        this.props.onTextColorChange(color.hex);
    };

    handleChangeBorderColor = (color) => {
        this.setState({ borderColor: color.hex });
        this.props.onBorderColorChange(color.hex);
    };



    render() {
        const { classes, open, track, playlist } = this.props;

        let sliderVolume = 0;
        let backgroundColor = "#000";
        let textColor = "#FFFF00";
        let borderColor = "#FFFF00";
        let description = "Test";
        if (track != null) {
            sliderVolume = track.volume ? track.volume * 100 : 100;
            sliderVolume = Math.round(sliderVolume);
            sliderVolume = sliderVolume;
            backgroundColor = typeof track.backgroundColor === "undefined" ? "#000" : track.backgroundColor;
            textColor = typeof track.textColor === "undefined" ? "#FFFF00" : track.textColor;
            borderColor = typeof track.borderColor === "undefined" ? "#FFFF00" : track.borderColor;
            description = track.description;
        }

        //Sätter valen i sorteringslistan
        let sortingOptions = [];
        if (playlist != null && track != null) {
            playlist.sections.forEach(section => {
                const index = section.links.findIndex(s => s.id === track.id);
                if (index > -1) {
                    sortingOptions = section.links.map((x, index) => {
                        return { "value": (index + 1), "label": (index + 1).toString() }
                    });
                }
            });
        }

        //Defaultvalue for the sorting
        const defaultValue = sortingOptions.find(x => x.value === track.sortIndex);
        return (
            <React.Fragment>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={open}
                    aria-labelledby="max-width-dialog-title"
                    classes={{ root: classes.dialogContent, paper: classes.dialogPaper }}
                >
                    <DialogTitle>{track != null ? track.description : ''}</DialogTitle>
                    <DialogContent>

                        <Grid container spacing={1}>
                            <Grid item xs={12} >
                                <TextField
                                    id="description"
                                    label="Beskrivning"
                                    value={track != null ? track.description : ''}
                                    onChange={this.handleChangeDescription()}
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                />
                                <TextField
                                    id="uri"
                                    label="Uri"
                                    value={track != null ? track.uri : ''}
                                    onChange={this.handleChangeUri()}
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                />

                                <TextField
                                    id="uri"
                                    label="Startposition (i sekunder)"
                                    value={track == null || isNaN(track.startAt) ? '' : track.startAt}
                                    onChange={this.handleChangeStartposition()}
                                    margin="normal"
                                    variant="outlined"
                                    className={classes.textField}
                                />

                                <Typography variant="body2">Volym: {sliderVolume}</Typography>
                                <Slider
                                    onChange={this.handleVolumeChange}
                                    value={sliderVolume}
                                    defaultValue={sliderVolume}
                                    trackStyle={{ backgroundColor: 'yellow', height: 5 }}
                                    handleStyle={{
                                        borderColor: 'yellow',
                                        height: 18,
                                        width: 18,
                                        marginLeft: -8,
                                        marginTop: -7,
                                        backgroundColor: 'black',
                                    }}
                                    railStyle={{ backgroundColor: 'yellow', height: 5 }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <br />
                                <Typography variant="body2" style={{ marginBottom: 6 }}>Sorteringsordning:</Typography>
                                <Select options={sortingOptions}
                                    defaultValue={defaultValue}
                                    placeholder="Sorteringsordning"
                                    styles={darkTheme}
                                    maxMenuHeight={200}
                                    onChange={this.handleChangeSortOrder} />
                            </Grid>

                            <Grid item xs={6}>
                                <br />
                                <Typography variant="body2" style={{ marginBottom: 6 }}>Förhandsgranskning:</Typography>
                                <Button
                                    className={classes.button} style={{
                                        backgroundColor: backgroundColor,
                                        color: textColor,
                                        borderColor: borderColor
                                    }}>
                                    <span style={{ overflow: 'hidden' }}>{description}</span>
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <br />
                                <Typography variant="body2" style={{ marginBottom: 6 }}>Bakgrundsfärg:</Typography>
                                <CompactPicker
                                    color={backgroundColor}
                                    onChangeComplete={this.handleChangeBackgroundColor}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <br />
                                <Typography variant="body2" style={{ marginBottom: 6 }}>Textfärg:</Typography>
                                <CompactPicker
                                    color={textColor}
                                    onChangeComplete={this.handleChangeTextColor}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <br />
                                <Typography variant="body2" style={{ marginBottom: 6 }}>Ramfärg:</Typography>
                                <CompactPicker
                                    color={borderColor}
                                    onChangeComplete={this.handleChangeBorderColor}
                                />
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>

                        <Button variant='outlined' onClick={this.handleDeleteTrack}
                            className={classes.deleteBtn}>Ta bort
                        </Button>
                        <Button variant='outlined' onClick={this.handleClose}
                            className={classes.button}>Avbryt
                        </Button>

                        <Button variant='contained' onClick={this.handleSave}
                            className={classes.button}>Spara
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

EditTrackDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(EditTrackDialog);
