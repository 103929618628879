import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import 'rc-slider/assets/index.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({

    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
    },
    deleteBtn: { border: "1px solid red" },
    button: {
        background: 'transparent',
        borderRadius: 3,
        border: '1px solid yellow',
        color: 'yellow',
        height: 33,
        marginRight: 3,
        marginLeft: 3,
        marginBottom: 7,
        pointer: 'cursor',
        overflow: 'hidden !important',
        width: 140,
        whiteSpace: 'nowrap',
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: '0.75rem'
    },
});


class ActivatePlayerDialog extends React.Component {

    constructor(props) {
        super(props);

    }

    handleActivatePlayer = () => {
        this.props.onActivatePlayer();
    };

    handleClose = () => {
        this.props.onActivatePlayer();
    };


    render() {
        const { classes, open, track } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Aktivera spelare på iOS"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Klicka på knappen för att aktivera spelaren på din iOS-enhet.
                        </DialogContentText>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleActivatePlayer}
                            className={classes.button}>
                            <span>Aktivera spelare</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ActivatePlayerDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(ActivatePlayerDialog);
