import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { db } from '../firebase/firebase';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddTrackItem from '../components/AddTrackItem';
import EditTrackItem from '../components/EditTrackItem';
import { FaEllipsisH } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { AuthContext } from '../firebase/authContext';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { getParameterByName, getSectionIndexByTrack, moveArrayElement } from '../common/helpers';
import ProgressIndicator from '../components/ProgressIndicator';
import PlaylistMenu from '../components/PlaylistMenu';
import SectionMenu from '../components/SectionMenu';
import CreateItemDialog from '../components/CreateItemDialog';
import EditSectionTitleDialog from '../components/EditSectionTitleDialog';
import { nanoid } from 'nanoid';
import ConfirmDialog from '../components/ConfirmDialog';
import { findIndex } from 'lodash';
import Snackbar from '../components/Snackbar';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { baseUri } from '../common/constants';
import EditTrackDialog from '../components/EditTrackDialog';
import cloneDeep from 'lodash/cloneDeep';
import ActivatePlayerDialog from '../components/ActivatePlayerDialog';

const styles = theme => ({
  root: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginBottom: 10
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: {
    marginBottom: 20,
    marginLeft: theme.spacing.unit * 2
  },
  trackInfoPaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: 20,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    background: '#000'
  },
  button: {
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid yellow',
    color: 'yellow',
    height: 33,
    marginRight: 3,
    marginLeft: 3,
    marginBottom: 7,
    pointer: 'cursor',
    overflow: 'hidden !important',
    width: 140,
    whiteSpace: 'nowrap',
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: '0.75rem'
  },
  buttonSaveToken: {
    width: '90%',
    height: 55
  },
  column: {
    flexBasis: '33.33%'
  },
  iconButton: {
    color: '#FFF',
    marginRight: 10,
    fontSize: 20
  },
});

class PlayPage extends React.Component {


  constructor(props) {

    super(props);

    this.handleTokenFieldChange = this.handleTokenFieldChange.bind(this);
    this.loginWithSpotify = this.loginWithSpotify.bind(this);
    this.checkForPlayer = this.checkForPlayer.bind(this);

    this.openAddSectionDialog = this.openAddSectionDialog.bind(this);
    this.closeAddSectionDialog = this.closeAddSectionDialog.bind(this);
    this.addSection = this.addSection.bind(this);

    this.toggleDeleteSectionDialog = this.toggleDeleteSectionDialog.bind(this);
    this.handleDeleteSection = this.handleDeleteSection.bind(this);

    this.toggleDeletePlaylistDialog = this.toggleDeletePlaylistDialog.bind(this);
    this.handleDeletePlaylist = this.handleDeletePlaylist.bind(this);

    this.toggleEditSectionTitleDialog = this.toggleEditSectionTitleDialog.bind(this);
    this.closeEditSectionTitleDialog = this.closeEditSectionTitleDialog.bind(this);


    this.saveSectionTitle = this.saveSectionTitle.bind(this);

    this.state = {
      accessToken: "",
      refreshToken: "",
      isLoading: true,
      deviceId: "",
      error: "",
      trackName: "Track Name",
      artistName: "Artist Name",
      albumName: "Album Name",
      playing: false,
      playingLink: {},
      position: 0,
      duration: 0,
      playlist: null,
      addSongDialogIsOpen: false,
      sectionIdInEditMode: "",
      createSectionDialogStatus: false,
      deleteSectionDialogIsOpen: false,
      deletePlaylistDialogIsOpen: false,
      editSectionTitleDialogStatus: false,
      sectionInEdit: null,
      selectedSection: null,
      snackbarMessage: "",
      snackbarIsOpen: false,
      snackbarIcon: <CheckIcon />,
      snackbarMessageColor: '#000',
      volume: 1,
      editTrackDialogIsOpen: false,
      editingTrack: null,
      isInitalizedOnIos: false,
      activatePlayerDialogIsOpen: false
    };

    this.playerCheckInterval = null;
    this.myButton = React.createRef();
  }

  fetchRefreshToken(checkForPlayer, refresh_token, callbackFn, callbackParam) {

    var self = this;

    //axios.get('http://localhost:3000/refresh_token', {
    axios.get(baseUri + '/auth/refresh_token', {
      params: {
        'refresh_token': refresh_token
      }
    }
    ).then(function (response) {
      localStorage.setItem('access_token', response.data.access_token);
      self.setState({
        accessToken: response.data.access_token
      }, function () {

        if (checkForPlayer === true) {

          self.playerCheckInterval = setInterval(self.checkForPlayer, 3000);
        }
        setTimeout(function () { self.fetchRefreshToken(false, refresh_token); console.log("FETCHING REFRESH TOKEN"); }, 1800000);
      });
    })
      .catch(function (error) {
        localStorage.clear();
        var url = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
        window.location.href = baseUri + "/auth/login?state=" + url;
      });

  }

  handleCloseSnackBar = () => {
    this.setState({ snackbarIsOpen: false });
  }

  loginWithSpotify() {

    var access_token = localStorage.getItem('access_token');
    var refresh_token = localStorage.getItem('refresh_token');

    if (access_token !== null && refresh_token !== null) {
      this.fetchRefreshToken(true, refresh_token);

    } else {
      localStorage.clear();
      var url = [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
      window.location.href = baseUri + "/auth/login?state=" + url;
    }
  }

  isIOS() {
    return false;
   
    
    //return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  componentDidMount() {

    var self = this;
    var playlistId = this.props.match.params.id;
    let userId = this.context.user.id;

    var hash_access_token = getParameterByName('access_token');
    var hash_refresh_token = getParameterByName('refresh_token');

    if (hash_access_token !== null && hash_refresh_token !== null) {

      window.history.replaceState({}, document.title, "/playlist/" + playlistId);

      localStorage.setItem('access_token', hash_access_token);
      localStorage.setItem('refresh_token', hash_refresh_token);

      this.setState({
        accessToken: hash_access_token,
        refreshToken: hash_refresh_token
      }, function () {
        self.playerCheckInterval = setInterval(self.checkForPlayer, 3000);
        setTimeout(function () { self.fetchRefreshToken(false, hash_refresh_token); console.log("FETCHING 111 REFRESH TOKEN"); }, 30000);
      });
    } else {
      this.loginWithSpotify();
    }

    var current = this;
    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(playlistId)

    docRef.get().then(function (doc) {

      var playlist = doc.data()
      playlist.id = doc.id;

      current.setState({ playlist: playlist });
    })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });

    docRef.onSnapshot(function (doc) {
      if (doc.exists) {
        var playlist = doc.data()
        playlist.id = doc.id;
        current.setState({ playlist: playlist });

        //Show a dialog to activate the player. (only concerns iOS devices)
       let isIOS = [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)

        if (isIOS) {
          self.setState({activatePlayerDialogIsOpen: true});
        }
      }
    });
  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  sleep = m => new Promise(r => setTimeout(r, m))

  stopPlay = async () => {

    var self = this;

    if (typeof this.player !== 'undefined') {

      var volume = await this.getVolumeAsync();
      volume = volume ? volume : 1;
      volume = Math.round(volume * 100) / 100;
      var step = volume / 10;
      const milliseconds = 150;

      await this.sleep(milliseconds);
      volume -= step;
      await this.setVolumeAsync(volume);

      await this.sleep(milliseconds);
      volume -= step;
      await this.setVolumeAsync(volume);

      await this.sleep(milliseconds);
      volume -= step;
      await this.setVolumeAsync(volume);

      await this.sleep(milliseconds);
      volume -= step;
      await this.setVolumeAsync(volume);

      await this.sleep(milliseconds);
      volume -= step;
      await this.setVolumeAsync(volume);

      await this.sleep(milliseconds);
      volume -= step;
      await this.setVolumeAsync(volume);

      await this.sleep(milliseconds);
      volume -= step;
      await this.setVolumeAsync(volume);

      await this.sleep(milliseconds);
      volume -= step;
      this.player.setVolume(volume);

      await this.sleep(milliseconds);
      volume -= step;
      this.player.setVolume(volume);

      await this.sleep(milliseconds);
      volume = 0;
      await this.setVolumeAsync(volume);

      self.player.pause();

      self.setState({
        playingLink: {},
        artistName: "",
        trackName: ""
      });
    }
  }

  getAudioAnalysis = async (uri) => {

    var uridd = uri.split(':')[2];

    await fetch(`https://api.spotify.com/v1/audio-features/${uridd}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.state.accessToken}`
      },
    }).then(function (resp) {
      resp.json().then(function (info) {
        console.log('Loudness: ' + info.loudness);
      });

    }).catch(function (error) {
      console.log("Spotify errorrr!: ", error);
    });
  }

  setVolumeAsync(volume) {
    var self = this;
    return new Promise((resolve) => {
      self.player.setVolume(volume).then(() => {
        resolve();
      });
    });
  }


  getVolumeAsync() {
    var self = this;
    return new Promise((resolve) => {
      self.player.getVolume().then(volume => {
        resolve(volume);
      });
    });
  }

  getPlayerState = async () => {

    return new Promise((resolve) => {
      this.player.getCurrentState().then(state => {
        if (!state) {
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }


  playTrack = async (link) => {

    let playerIsReady = await this.getPlayerState();

    if (!playerIsReady) {
      await this.transferPlaybackHere();
    }
    //this.getAudioAnalysis(link.uri);
    let self = this;
    var vol = link.volume ? link.volume : 1;

    await this.setVolumeAsync(vol);

    fetch(`https://api.spotify.com/v1/me/player/play?device_id=${this.state.deviceId}`, {
      method: 'PUT',
      body: JSON.stringify({ "uris": [link.uri], "position_ms": (link.startAt * 1000) }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.state.accessToken}`
      },
    }).then(function (resp) {

      if (!resp.ok) {
        self.setState({
          snackbarMessage: 'Frånkopplad, vänta återansluter...',
          snackbarMessageColor: 'red',
          snackbarIsOpen: true,
          snackbarIcon: <ClearIcon style={{ color: 'red' }} />
        });
        // window.location.href = window.location.href.replace( /[\?#].*|$/, "?" + link.id );
        window.location.reload();
        /*      if (authErrorStatusCodes.indexOf(resp.status) > -1) {
               self.loginWithSpotify();
     
              } else {
                window.location.reload(); */
        // throw Error(resp);
        // }
      }

      //  if (okStatusCodes.indexOf(resp.status) > -1) {
      self.setState({
        playingLink: link
      });
      // }
    }).catch(function (error) {
    });
  }

  onStateChanged(state) {

    // if we're no longer listening to music, we'll get a null state.
    if (state !== null) {
      const {
        current_track: currentTrack,
        position,
        duration,
      } = state.track_window;

      const trackName = currentTrack !== null ? currentTrack.name : "";
      const albumName = currentTrack !== null ? currentTrack.album.name : "";
      const artistName = currentTrack !== null ? currentTrack.artists.map(artist => artist.name).join(", ") : "";
      const playing = !state.paused;
      this.setState({
        position,
        duration,
        trackName,
        albumName,
        artistName,
        playing
      });
    }
  }

  checkForPlayer() {

    const { accessToken } = this.state;

    if (window.Spotify !== null) {

      clearInterval(this.playerCheckInterval);

      this.player = new window.Spotify.Player({
        name: "Spotify Player",
        getOAuthToken: cb => {
          cb(accessToken);
        },
      });
      this.createEventHandlers();
      this.player.connect();
    }
  }

  createEventHandlers() {

    var self = this;

    this.player.on('initialization_error', e => {
      this.loginWithSpotify();
    });
    this.player.on('authentication_error', e => {
      this.loginWithSpotify();
    });
    this.player.on('account_error', e => {
    });
    this.player.on('playback_error', e => {
      window.location.reload();
    });

    this.player.on('not_ready', ({ device_id }) => {
      self.setState({
        deviceId: device_id,
        isLoading: true
      });
    });

    // Playback status updates
    this.player.on('player_state_changed', state => this.onStateChanged(state));

    this.player.on('ready', async data => {
      let { device_id } = data;

      self.setState({
        snackbarMessage: 'Anslutning OK',
        snackbarMessageColor: '#000',
        snackbarIsOpen: true,
        snackbarIcon: <CheckIcon />
      });
      await this.setState({
        deviceId: device_id,
        isLoading: false
      });
      await this.transferPlaybackHere();
    });
  }

  checkPlayer = async () => {

    return new Promise((resolve) => {
      const { accessToken } = this.state;

      fetch("https://api.spotify.com/v1/me/player", {
        method: "GET",
        headers: {
          authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }
      }).then(function (resp) {
        resolve(resp);
      }).catch(function (error) {
      });
    });
  }

  async transferPlaybackHere() {

    return new Promise((resolve) => {

      const { deviceId, accessToken } = this.state;

      var self = this;

      fetch("https://api.spotify.com/v1/me/player", {
        method: "PUT",
        headers: {
          authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "device_ids": [deviceId],
          "play": false,
        }),
      }).then(function (resp) {
        resolve();
      }).catch(function (error) {
        console.log("Spotify error: ", error);
      });

    });
  }

  handleTokenFieldChange(e) {
    this.setState({ accessToken: e.target.value });
  }

  handleOpenEditTrackDialog = (track, sortIndex) => {
    track.sortIndex = sortIndex + 1;
    this.setState({
      editTrackDialogIsOpen: true,
      editingTrack: track
    });
  }

  handleEditTrackDescription = (description) => {
    let track = Object.assign({}, this.state.editingTrack);
    track.description = description;
    this.setState({ editingTrack: track });
  }

  handleEditUri = (uri) => {
    let track = Object.assign({}, this.state.editingTrack);
    track.uri = uri;
    this.setState({ editingTrack: track });
  }

  handleEditStartPosition = (startAt) => {
    let track = Object.assign({}, this.state.editingTrack);
    track.startAt = startAt;
    this.setState({ editingTrack: track });
  }

  handleChangeBgColor = (hex) => {
    let track = Object.assign({}, this.state.editingTrack);
    track.backgroundColor = hex;
    this.setState({ editingTrack: track });
  }

  handleTextColorChange = (hex) => {
    let track = Object.assign({}, this.state.editingTrack);
    track.textColor = hex;
    this.setState({ editingTrack: track });
  }

  handleBorderColorChange = (hex) => {
    let track = Object.assign({}, this.state.editingTrack);
    track.borderColor = hex;
    this.setState({ editingTrack: track });
  }

  handleVolumeChange = (volumeVal) => {
    let track = Object.assign({}, this.state.editingTrack);
    const volume = volumeVal / 100;
    track.volume = volume;
    this.setState({ editingTrack: track });
  }

  handleChangeSortOrder = (toIndex) => {
    let track = Object.assign({}, this.state.editingTrack);
    track.sortIndex = toIndex;
    this.setState({ editingTrack: track });
  }

  handleDeleteTrack = () => {
    const self = this;
    let playlist = cloneDeep(this.state.playlist);
    let track = Object.assign({}, this.state.editingTrack);

    let sIndex = -1;
    let trackIndex = -1
    playlist.sections.forEach((section, sectionIndex) => {

      let index = section.links.findIndex(link => link.id === track.id);
      if (index > -1) {
        sIndex = sectionIndex;
        trackIndex = index;
      }
    });
    const index = findIndex(playlist.sections[sIndex].links, function (o) { return o.id === track.id });

    playlist.sections[sIndex].links.splice(index, 1);

    let userId = this.context.user.id;
    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(playlist.id);

    docRef.update({
      sections: playlist.sections,
    }).then(function () {
      self.setState({ editTrackDialogIsOpen: false })
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

  }

  handleActivatePlayer = () => { 
    this.checkForPlayer();
    this.setState({ activatePlayerDialogIsOpen: false });
  }

  handleSaveTrack = () => {
    let playlist = cloneDeep(this.state.playlist);
    const editingTrack = Object.assign({}, this.state.editingTrack);
    console.log(editingTrack);
    //Finding the track and updates the track in the collection
    playlist.sections.forEach(section => {
      let index = section.links.findIndex(track => track.id === this.state.editingTrack.id);
      if (index !== -1) {
        section.links[index] = editingTrack;
      }
    });

    if (typeof editingTrack.sortIndex !== undefined) {
      const sectionIndex = getSectionIndexByTrack(editingTrack, playlist);
      if (sectionIndex > -1) {
        let fromIndex = playlist.sections[sectionIndex].links.findIndex(link => link.id === editingTrack.id);
        playlist.sections[sectionIndex].links = moveArrayElement(playlist.sections[sectionIndex].links, fromIndex, editingTrack.sortIndex - 1);
      }
    }

    const userId = this.context.user.id;
    const self = this;
    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(playlist.id);
    docRef.update({
      sections: playlist.sections,
    }).then(function () {
      self.setState({
        snackbarMessage: 'Länk uppdaterad',
        snackbarMessageColor: '#000',
        snackbarIsOpen: true,
        snackbarIcon: <CheckIcon />,
        editTrackDialogIsOpen: false
      });
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  editTracks(id) {
    if (this.state.sectionIdInEditMode === "") {
      this.setState({ sectionIdInEditMode: id });
    } else {
      this.setState({ sectionIdInEditMode: "" });
    }
  }

  getButton(playingLink, link, playlist, section, sectionIndex, sortIndex) {
    const { classes } = this.props;
    if (this.state.sectionIdInEditMode === section.id) {
      return <EditTrackItem
        key={"edit_" + link.id}
        sectionIndex={sectionIndex}
        playlist={playlist}
        link={link}
        sortIndex={sortIndex}
        onEditClick={() => this.handleOpenEditTrackDialog(link, sortIndex)} />
    }
    else if (playingLink.id === link.id) {
      return <Button style={{ backgroundColor: 'yellow', color: '#000' }} key={"stop_" + link.id}
        onClick={this.stopPlay}
        className={classes.button}>Stop</Button>
    }
    else {
      return <Button key={"play_" + link.id} onClick={() => this.playTrack(link)}
        className={classes.button} style={{
                                          backgroundColor: typeof link.backgroundColor === "undefined" ? "#000" : link.backgroundColor,
                                          color: typeof link.textColor === "undefined" ? "#FFFF00" : link.textColor,
                                          borderColor: typeof link.borderColor === "undefined" ? "#FFFF00" : link.borderColor }}>
        <span style={{ overflow: 'hidden' }}>{link.description}</span>
      </Button>
    }
  }

  openAddSectionDialog() {
    this.setState({ createSectionDialogStatus: true });
  }

  closeAddSectionDialog() {
    this.setState({ createSectionDialogStatus: false });
  }

  closeEditSectionTitleDialog() {
    this.setState({ editSectionTitleDialogStatus: false });
  }

  saveSectionTitle(title, section) {

    let playlist = cloneDeep(this.state.playlist);
    const index = findIndex(playlist.sections, function (o) { return o.id === section.id });
    playlist.sections[index].description = title;

    var self = this;

    let userId = this.context.user.id;

    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(playlist.id);

    docRef.update({
      sections: playlist.sections
    }).then(function () {
      self.closeEditSectionTitleDialog();
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  addSection(description) {

    var self = this;
    let userId = this.context.user.id;
    var newSection = {
      id: nanoid(),
      description: description,
      links: []
    }

    var playlist = Object.assign({}, this.state.playlist);

    playlist.sections.push(newSection);

    console.log('playlist ', playlist);

    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(playlist.id);

    docRef.update({
      sections: playlist.sections
    }).then(function () {
      self.closeAddSectionDialog();
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

  }

  toggleDeleteSectionDialog(section) {

    this.setState({
      deleteSectionDialogIsOpen: !this.state.deleteSectionDialogIsOpen,
      selectedSection: section
    });
  }

  toggleEditSectionTitleDialog(section) {
    this.setState({
      editSectionTitleDialogStatus: !this.state.editSectionTitleDialogStatus,
      sectionInEdit: section
    });
  }

  toggleDeletePlaylistDialog() {

    this.setState({
      deletePlaylistDialogIsOpen: !this.state.deletePlaylistDialogIsOpen
    });
  }

  handleDeleteSection() {

    var self = this;
    var playlist = cloneDeep(this.state.playlist);
    var section = Object.assign({}, this.state.selectedSection);
    const index = findIndex(playlist.sections, function (o) { return o.id === section.id });

    playlist.sections.splice(index, 1);

    let userId = this.context.user.id;

    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(playlist.id);

    docRef.update({
      sections: playlist.sections,
    }).then(function () {
      self.toggleDeleteSectionDialog();
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

  }

  handleDeletePlaylist() {

    var self = this;
    let userId = this.context.user.id;
    var playlist = Object.assign({}, this.state.playlist);

    db.collection("users").doc(userId)
      .collection('playlists').doc(playlist.id).delete().then(function () {
        self.props.history.push("/");
      }).catch(function (error) {
        console.error("Error removing document: ", error);
      });

  }

  static contextType = AuthContext;

  render() {

    const {
      artistName,
      trackName,
      error,
      playing,
      playingLink,
      playlist,
      isLoading,
      sectionInEdit
    } = this.state;

    const { classes } = this.props;

    if (playlist !== null && isLoading == false) {
      //  if(playlist !== null) {
      return (
        <React.Fragment>

          <Snackbar
            open={this.state.snackbarIsOpen}
            icon={this.state.snackbarIcon}
            messageColor={this.state.snackbarMessageColor}
            snackbarMessage={this.state.snackbarMessage}
            snackbarOnClose={this.handleCloseSnackBar}
          />

          <ActivatePlayerDialog
            open={this.state.activatePlayerDialogIsOpen}
            onClose={() => this.setState({ activatePlayerDialogIsOpen: false })}
            onActivatePlayer={this.handleActivatePlayer}
          />

          <EditTrackDialog
            open={this.state.editTrackDialogIsOpen}
            onClose={() => this.setState({ editTrackDialogIsOpen: false })}
            track={this.state.editingTrack}
            onEditDescription={this.handleEditTrackDescription}
            onEditUri={this.handleEditUri}
            onEditStartPosition={this.handleEditStartPosition}
            onBgColorChange={this.handleChangeBgColor}
            onTextColorChange={this.handleTextColorChange}
            onBorderColorChange={this.handleBorderColorChange}
            onSave={this.handleSaveTrack}
            onVolumeChange={this.handleVolumeChange}
            onSortOrderChange={this.handleChangeSortOrder}
            onDeleteTrack={this.handleDeleteTrack}
            playlist={playlist}
          />

          <CreateItemDialog
            dialogStatus={this.state.createSectionDialogStatus}
            dialogTitle="Skapa sektion"
            dialogDesc="Ange beskrivning på sektionen&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
            dialogLabel="Sektionens namn:"
            onClose={this.closeAddSectionDialog}
            onAdd={this.addSection}
            addLabel="Skapa sektion" />

          <EditSectionTitleDialog
            dialogStatus={this.state.editSectionTitleDialogStatus}
            dialogTitle="Ändra namn på sektion"
            dialogDesc="Ange nytt namn på sektionen&nbsp;&nbsp;&nbsp;&nbsp;"
            dialogLabel="Sektionens namn:"
            onClose={this.closeEditSectionTitleDialog}
            onAdd={this.saveSectionTitle}
            section={sectionInEdit}
            addLabel="Spara" />

          <ConfirmDialog
            open={this.state.deleteSectionDialogIsOpen}
            title={'Ta bort sektion'}
            body={'Vill du verkligen ta bort sektionen?'}
            toggleDialog={this.toggleDeleteSectionDialog}
            confirm={this.handleDeleteSection} />

          <ConfirmDialog
            open={this.state.deletePlaylistDialogIsOpen}
            title={'Ta bort spellista'}
            body={'Vill du verkligen ta denna spellista?'}
            toggleDialog={this.toggleDeletePlaylistDialog}
            confirm={this.handleDeletePlaylist} />

          <Grid container spacing={8}>

            <Grid item xs={12}>
              <Paper className={classes.trackInfoPaper}>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {playing === true ?
                    <Typography variant="h6" style={{ display: 'inline-block' }}>
                      {artistName} - {trackName}
                    </Typography> :
                    <Typography variant="h6" style={{ display: 'inline-block' }} className={classes.grow}>
                      Ingen låt spelas...
                    </Typography>}
                  {error && <p>Error: {error}</p>}
                  <PlaylistMenu
                    openAddSectionDialog={this.openAddSectionDialog}
                    toggleDeletePlaylistDialog={this.toggleDeletePlaylistDialog}
                    style={{ marginLeft: 'auto' }} />
                </div>

              </Paper>
            </Grid>
          </Grid>

          {playlist.sections.map((section, index) => {
            return (
              <div className={classes.root} key={section.id}>
                <ExpansionPanel expanded={true} style={{ backgroundColor: '#000' }}>

                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div className={classes.column}>
                      <Typography className={classes.heading}>{section.description}</Typography>
                    </div>
                    <div className={classes.column} />
                    <div className={classes.column} style={{ textAlign: 'right' }}>

                      <FiSettings className={classes.iconButton} value={section.id} onClick={() => this.editTracks(section.id)} />
                      <AddTrackItem addOrEdit={'add'} sectionIndex={index} playlist={playlist} />

                      <SectionMenu
                        section={section}
                        toggleDeleteSectionDialog={this.toggleDeleteSectionDialog}
                        toggleEditSectionTitleDialog={this.toggleEditSectionTitleDialog}
                        style={{ marginLeft: 'auto' }} />

                    </div>
                  </ExpansionPanelSummary>
                  <div style={{ padding: '7px 7px' }}>
                    {section.links.map((link, sortIndex) => {
                      return this.getButton(playingLink, link, playlist, section, index, sortIndex)
                    })}
                  </div>
                </ExpansionPanel>
              </div>
            )
          })}
          {/* {this.context.user.email === "staffan@solutionly.se" ? <Button
            ref={this.myButton}
            style={{ backgroundColor: 'yellow', color: '#000' }}
            onClick={this.checkForPlayer}>
            Testings
          </Button> : null} */}
        </React.Fragment>
      )
    }
    return <ProgressIndicator loadingDesc={'Ansluter till Spotify...'} />
  }
}


export default withStyles(styles)(PlayPage);