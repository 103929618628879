import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { MdMenu } from "react-icons/md";

const styles = theme => ({
  iconButton: {
    color: '#FFF',
    marginRight: 10,
    marginLeft: 10,
    fontSize: 20
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  }
});

class PlaylistMenu extends React.Component {

  constructor(props) {
    super(props);

  this.state = {
    anchorEl: null,
  };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  openDeleteSectionDialog = () => {
    this.props.toggleDeleteSectionDialog(this.props.section);
    this.setState({ anchorEl: null });
  };

  openEditSectionTitle = () => {
    this.props.toggleEditSectionTitleDialog(this.props.section);
    this.setState({ anchorEl: null });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {

    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
         <MdMenu 
         className={classes.iconButton}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
        </MdMenu>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.openEditSectionTitle}>Redigera namn</MenuItem>
          <MenuItem onClick={this.openDeleteSectionDialog}>Radera sektion</MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PlaylistMenu);
//export default PlaylistMenu;
