import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({ 

  button: {
    color: '#FFF',
    backgroundColor: '#509CF5',
    '&:hover': {
      backgroundColor: '#0d74eb',
      color: '#FFF',
    },
    '&:active': {
      backgroundColor: '#0d74eb',
    }
  },
  cancelButton: {
    color: '#509CF5'
  }
});

class CreateItemDialog extends React.Component {

  constructor(props) {
    super(props); 
  
    this.state = {
      description: ""
    }

  }

  handleInputChange = (e) => {
    this.setState({
      description: e.target.value
    });

  }

  handleClose = (e) => {

    this.props.onClose();

  }

  handleAdd = () => {

    this.props.onAdd(this.state.description);

  }

  handleKeyPress = (event) => {

    if(event.key === 'Enter'){
  
      this.props.onAdd(this.state.description);
      
    }
  }

    render() {

      const { classes } = this.props;

    return (
        <Dialog
        open={this.props.dialogStatus}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">{this.props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {this.props.dialogDesc}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={this.props.dialogLabel}
            type="text"
            fullWidth
            onChange={(e) => this.handleInputChange(e)}
            onKeyPress={this.handleKeyPress}
            />
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancelButton} onClick={() => this.handleClose()}>
            Avbryt
          </Button>
          <Button className={classes.button} onClick={() => this.handleAdd()}>
           {this.props.addLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
    }
  }

  export default withStyles(styles)(CreateItemDialog);