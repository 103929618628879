import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EnhancedTableHead from '../components/EnhancedTableHead';
import EnhancedTableToolbar from '../components/EnhancedTableToolbar';
import { db } from '../firebase/firebase';
import { getSorting, stableSort } from '../common/helpers';
import { AuthContext } from '../firebase/authContext';
import Button from '@material-ui/core/Button';

const rows = [
  { id: 'description', numeric: false, disablePadding: true, label: 'Description' },
  { id: 'tracksLength', numeric: true, disablePadding: true, label: 'Antal länkar' },
  { id: 'sectionsLength', numeric: true, disablePadding: true, label: 'Antal sektioner' },
];

const styles = theme => ({
  root: {
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    marginBottom: 15
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  }
});

class EnhancedTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 5,
      playlists: null
    };

    this.fetchPlaylists = this.fetchPlaylists.bind(this);
  }


 handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };



  componentDidMount() {
   
    this.fetchPlaylists();
  }

  fetchPlaylists() {
    
    var self = this;
    var tempPlaylists = [];
    let userId = this.context.user.id;
    
    db.collection("users").doc(userId)
    .collection('playlists')
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
          
            var p = { id: doc.id, description: doc.data().description, sections: doc.data().sections }
              tempPlaylists.push(p);
          });
          self.setState({ playlists: tempPlaylists, rowsPerPage: tempPlaylists.length });
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
  
  }


  handleClick = (event, id) => {

    this.props.history.push("playlist/" + id);
    
  };

  static contextType = AuthContext;

  render() {
    const { classes } = this.props;
    const { playlists, data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    if(playlists !== null) {
      
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
        playlists={playlists}
        fetchPlaylists={this.fetchPlaylists}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />
            <TableBody>
              {stableSort(this.state.playlists, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={n.id}
                      style={{cursor: 'pointer'}}
                    >
                       <TableCell component="th" scope="row">
                        {n.description}
                      </TableCell>

                      <TableCell align="right"></TableCell>

                      <TableCell align="right">{n.sections.length}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> */}
      </Paper>
    );
  }
  return(<Typography>Loading</Typography>)
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
