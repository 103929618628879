
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AddPlayListDialog from '../components/AddPlaylistDialog';
import { AuthContext } from '../firebase/authContext';
import { db } from '../firebase/firebase';

const toolbarStyles = theme => ({
    root: {
      paddingRight: theme.spacing.unit,
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    },
  });
  
  class EnhancedTableToolbar extends React.Component {

    constructor(props) {
      super(props);
  
      this.handleSavePlaylist = this.handleSavePlaylist.bind(this);

    }

    handleSavePlaylist(playlist) { 

      let userId = this.context.user.id;
      var self = this;

      db.collection("users").doc(userId)
      .collection('playlists').add(playlist)
    .then(function() {
        self.props.fetchPlaylists();
    })
    .catch(function(error) {
        console.error("Error writing document: ", error);
    });

    }

    static contextType = AuthContext;

    render() {

    const { classes, playlists } = this.props;

    return (
      <Toolbar
        className={classes.root}>
        <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Spellistor
            </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
       {/*      <Button onClick={() => this.createPlaylist()}
             color="primary" variant="outlined" style={{minWidth: 200}}>
            Skapa spellista
          </Button> */}

      <AddPlayListDialog
      playlists={playlists}
      savePlaylist={this.handleSavePlaylist}
      />
        </div>
      </Toolbar>
    );
  }
  };
  
  EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  export default withStyles(toolbarStyles)(EnhancedTableToolbar);
  