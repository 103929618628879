import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { MdMenu } from "react-icons/md";

const styles = theme => ({
  iconButton: {
    color: '#FFF',
    marginRight: 10,
    fontSize: 20
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

class PlaylistMenu extends React.Component {

  constructor(props) {
    super(props);

  this.state = {
    anchorEl: null,
  };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  openAddSectionDialog = () => {
    this.props.openAddSectionDialog();
    this.setState({ anchorEl: null });
  };

  openDeletePlaylistDialog = () => {
    this.props.toggleDeletePlaylistDialog();
    this.setState({ anchorEl: null });
  };

  

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {

    const { classes } = this.props;
    const { anchorEl } = this.state;

    return (
      <div style={{display: 'inline-block'}}>
        <Button
            className={classes.iconButton}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
            <MenuIcon />
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.openAddSectionDialog}>Skapa sektion</MenuItem>
          <MenuItem onClick={this.openDeletePlaylistDialog}>Radera spellista</MenuItem>
    {/*       <MenuItem onClick={this.handleClose}>My account</MenuItem>
          <MenuItem onClick={this.handleClose}>Logout</MenuItem> */}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(PlaylistMenu);
//export default PlaylistMenu;
