import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import { nanoid  } from 'nanoid';
import { AuthContext } from '../firebase/authContext';
import { db } from '../firebase/firebase';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  popover: {

  },
    typography: {
    margin: theme.spacing.unit * 2,
  },
  textField: {
    width: '400px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: '8px',
  },
  confirmButton: {
    width: '400px',
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid #00FF00',
    color: '#00FF00',
    height: 50,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: '8px',
    marginBottom: '11px',
  },
  iconButton: {
    color: '#FFF',
    fontSize: 20
  },
  button: {
      background: 'transparent',
      borderRadius: 3,
      border: '1px solid yellow',
      color: 'yellow',
      height: 40,
      padding: '0 30px',
      marginRight: 10,
      marginBottom: 10
  }
});

class AddPlaylistDialog extends React.Component {

  constructor() {
    super()
    this.savePlaylist = this.savePlaylist.bind(this);

    this.state = {
      anchorEl: null,
      description: "",
      uri: "",
      startAt: ""
    };
  }

 savePlaylist() {

  var newPlaylist = {
    id: nanoid(),
    sections: [],
    description: this.state.description,
  }

  this.handleClose(); 

  this.props.savePlaylist(newPlaylist);

  /*  let userId = this.context.user.id;

 var docRef =  db.collection("users").doc(userId)
  .collection('playlists').doc(this.props.playlist.id); 

  docRef.update({
    sections: playlist.sections
  }).then(function() {
}).catch(function(error) {
    console.log("Error getting document:", error);
}); */

 }
 
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCopyPlaylist = async (event) => {
 
    let fromUserId = 'ur0UBfF6K7HKXQfcJXDJ';
    let fromPlaylistId = 'YBUcgrPg1CcthTKdqtMi';

    let targetUserId = 'y9ONJCjdLLduISBT030g';
    let targetPlaylistId = 'YBUcgrPg1CcthTKdqtMi';

    try { 
   
    let playlist = await db.collection('users').doc(fromUserId).collection('playlists').doc(fromPlaylistId).get();
    const data = playlist.data();
      console.log(data);
    if(data){
    let targetPlaylist = await db.collection("users").doc(targetUserId).collection('playlists').doc(targetPlaylistId);
     targetPlaylist.set(data);
    }
    alert("Spellista kopierad From:" + fromUserId + " " + targetUserId);
    }
    catch (error) {
      console.log(error);
    }

  }

  static contextType = AuthContext;

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
          {/* <FiPlusCircle className={classes.iconButton} onClick={this.handleClick}/> */}
        {/* <Button onClick={this.handleCopyPlaylist} className={classes.button}
             color="primary" variant="outlined" style={{minWidth: 200}}>
            Kopiera
          </Button>  */}
           <Button onClick={this.handleClick} className={classes.button}
             color="primary" variant="outlined" style={{minWidth: 200}}>
            Skapa spellista
          </Button>
        <Popover
        PaperProps={{
          style: {
            backgroundColor: '#080808',
            boxShadow: 'none',
          },
        }}
        className={classes.popover}
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
       {/*  <Typography variant="h5" className={classes.typography}>Lägg till låt</Typography> */}
        <div>
           <TextField
          id="outlined-name"
          label="Beskrivning"
          className={classes.textField}
          style={{marginTop: '11px'}}
          value={this.state.description}
          onChange={this.handleChange('description')}
          margin="normal"
          variant="outlined"
        />
        </div>
        <div>
        </div>
        <Button variant="outlined"
        color="primary"
        className={classes.confirmButton}
        onClick={this.savePlaylist}>
        Skapa spellista
      </Button>
        </Popover>
      </React.Fragment>
    );
  }
}

AddPlaylistDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddPlaylistDialog);