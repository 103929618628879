import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { getParameterByName } from '../common/helpers';
import { baseUri } from '../common/constants';

const styles = theme => ({
 
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  textField: {
    marginLeft: 0,
    marginRight: 20
  },
  dense: {
    marginTop: 16,
  },
  button: {
    marginLeft: 0,
    marginRight: 20,
    marginTop: 20,
    minHeight: 80,
    color: 'white',
    margin: 'auto',
    width: '100%',
  },
  icon: {
    marginLeft: 5
  },
});

class SignInPage extends React.Component {
  
  componentDidMount(){
   
    var code = getParameterByName('code');
    var state = getParameterByName('state');

    var callbackUrl = baseUri + '/auth/callback?code=' + code + '&state=' + state;
    window.location.href = callbackUrl;

  }


  render() {

    const { classes } = this.props;
  
const startDialog = 
<Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
>

    <Grid item xs={12} sm={8}>
    <Paper className={classes.root} elevation={1}>
      <div>
      <Typography variant="h5">
      Loading, please wait...
      </Typography>
        </div>
      </Paper>
</Grid>
</Grid>

  return (
       <div className={classes.root}>
    
      {startDialog}
      </div>
  );
}
}

SignInPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignInPage);
