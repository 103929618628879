import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import grey from '@material-ui/core/colors/grey';
import { BrowserRouter as Router, Route } from "react-router-dom";
import PlayListPage from './pages/PlayPage';
import Callback from './pages/Callback';
import TempPlaylistpage from './pages/TempPlaylistPage';
import EnhancedTableHead from './components/EnhancedTableHead';
import SignInPage from '../src/pages/SignInPage';
import ProfilePage from '../src/pages/ProfilePage';
import ProtectedRoute from './components/ProtectedRoute';
import ToolbarContent from './components/SLinkToolbar';
import { AuthProvider } from './firebase/authContext';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    marginLeft: 20
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: '#000',
    },
    //secondary: grey,
    //textColor: '#fff',
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: 'black', // Change this to your desired color
        color: 'yellow'
      },
    },
    MuiTypography: {
      title: {
        color: 'white', // Change this to your desired color
      },
    },
    MuiFormLabel: {
      root: {
        color: 'white', 
        '&$focused': {
          color: 'white', // Change this to your desired color
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'white', // Change this to your desired color
        },
        '&:hover $notchedOutline': {
          borderColor: 'white', // Change this to your desired color
        },
        '&.Mui-focused $notchedOutline': {
          borderColor: 'white', // Change this to your desired color
        },
      },
      input: {
        color: 'white', // Change this to your desired color
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          color: 'red', // Change this to your desired color
        },
      },
    },
    MuiButton: {
      root: {
        // Styles for the root
        color: 'black', // Change this to your desired color
        backgroundColor: 'black', // Change this to your desired color
      },
      contained: {
        // Styles for the contained variant
        color: 'black', // Change this to your desired color
        backgroundColor: 'white', // Change this to your desired color
      },
      outlined: {
        // Styles for the outlined variant
        color: 'white', // Change this to your desired color
        borderColor: 'white', // Change this to your desired color
      },
    },
  }
});

class App extends React.Component {


  render() {

    const { classes } = this.props;

    return (
      <AuthProvider>
        <Router>

          <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
              <AppBar position="static" style={{ marginBottom: 30 }}>
                <ToolbarContent />
              </AppBar>
              <Route path='/SignIn' component={SignInPage} />
              <ProtectedRoute path="/" exact component={TempPlaylistpage} />
              <ProtectedRoute path="/playlist/:id" exact component={PlayListPage} />
              <ProtectedRoute path="/head" exact component={EnhancedTableHead} />
              <ProtectedRoute path="/profile" exact component={ProfilePage} />
              <ProtectedRoute path="/callback" exact component={Callback} />
            </MuiThemeProvider>
          </div>

        </Router>
      </AuthProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
