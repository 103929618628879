import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import { signIn } from '../firebase/auth';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { IoIosLogIn } from "react-icons/io";
import ProgressIndicator from '../components/ProgressIndicator';

const styles = theme => ({
 
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  loginWrapper: {
    padding: theme.spacing.unit * 3,
    border: '1px solid yellow',
    backgroundColor: '#202329'
  },
  textField: {
    marginLeft: 0,
    marginRight: 20,
    backgroundColor: 'transparent'
  },
  dense: {
    marginTop: 16,
  },
  button: {
    marginLeft: 0,
    marginRight: 20,
    marginTop: 20,
    minHeight: 80,
    color: 'white',
    margin: 'auto',
    width: '100%',
  },
  icon: {
    marginLeft: 5
  }
});

class SignInPage extends React.Component {

  constructor(props) {
    super(props);

    const INITIAL_STATE = {
      email: '',
      password: '',
      error: null,
    };

    this.state = { 
      ...INITIAL_STATE,
      loading: false,
      showEmailError: false,
      showPasswordError: false
     };

  }

submitForm = (event) => {

  this.setState(state => ({
    loading: !state.loading,
  }));

  const {
    email,
    password,
  } = this.state;

   signIn(email, password)
  .then(authUser => {
   window.location.href = "/";
    })
    .catch(error => {
  });
}

handleChange = name => event => {
  this.setState({
    [name]: event.target.value.toLowerCase(),
  });
};

handleKeyPress = (event) => {

  if(event.key === 'Enter'){

    this.submitForm();
  }
}

  render() {

    const {
      email,
      password,
      showEmailError,
      showPasswordError,
      loading
    } = this.state;

    const { classes } = this.props;
  
const startDialog = 
<Zoom in={true}>
<Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
>

    <Grid item xs={12} sm={8}>
    <Paper className={classes.loginWrapper} elevation={1}>
      <div>
      <Typography variant="h5">
      Logga in här...
      </Typography>
        <TextField
          error={showEmailError}
          id="outlined-name"
          label="E-post"
          className={classes.textField}
          value={email}
          onChange={this.handleChange('email')}
          onKeyPress={this.handleKeyPress}
          margin="normal"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            style: {
              color: '#FFF'
            }}}
        />
        </div>
        <div>
          <TextField
          error={showPasswordError}
          id="outlined-password-input"
          label="Lösenord"
          className={classes.textField}
          value={password}
          onChange={this.handleChange('password')}
          onKeyPress={this.handleKeyPress}
          type="password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            style: {
              color: '#FFF'
            }}}
        />
        </div>
        <div>
        <Button 
variant="contained"
color="primary"
className={classes.button}
onClick={this.submitForm}>
 Logga in&nbsp;&nbsp;
<Typography variant="h5"><IoIosLogIn style={{ verticalAlign: 'baseline', paddingTop: 7, color: 'white' }} /></Typography>
</Button>
        </div>
      </Paper>
</Grid>
</Grid>
</Zoom>

  return (
       <div className={classes.root}>
       {loading && <ProgressIndicator />}
      {startDialog}
      </div>
  );
}
}

SignInPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignInPage);
