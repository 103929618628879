import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';

const styles = theme => ({
  grow: {
    flexGrow: 1,
    marginLeft: 20
  },
});

class SLinkToolbar extends React.Component {

  render() {

    const { classes } = this.props;

    return (
      <Toolbar>

          {/*  <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton> */}
    <Typography variant="h6" color="inherit" className={classes.grow}>
          Invibe
          </Typography>
          <Button onClick={() => this.props.history.push("/")} color="inherit">Spellistor</Button>

          <IconButton
                  aria-haspopup="true"
                  onClick={() => this.props.history.push("/profile")}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>

          </Toolbar>
    );
  }
}
export default withRouter(withStyles(styles)(SLinkToolbar));
