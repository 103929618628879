import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

firebase.initializeApp({
  apiKey: "AIzaSyAtZ7Iy2Yock7UeW6TwAtStpa31I309WRA",
  authDomain: "invibe-prod.firebaseapp.com",
  databaseURL: "https://invibe-prod.firebaseio.com",
  projectId: "invibe-prod",
  storageBucket: "",
  messagingSenderId: "851915832229",
  appId: "1:851915832229:web:b6582010f0aa6624e3864c",
  measurementId: "G-T1NHNT6QL2"
});

/* firebase.initializeApp({
  apiKey: "AIzaSyCBpbBA0BYS-eQ3oVe17rCcHRlpC1vQ5e0",
  authDomain: "soundlink-prod.firebaseapp.com",
  databaseURL: "https://soundlink-prod.firebaseio.com",
  projectId: "soundlink-prod",
  storageBucket: "soundlink-prod.appspot.com",
  messagingSenderId: "901875725968",
  appId: "1:901875725968:web:465ad584c47e4f16"
});
 */

const db = firebase.firestore();

const auth = firebase.auth();

export {
  db,
  auth,
};