import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import { db } from '../firebase/firebase';
import { AuthContext } from '../firebase/authContext';
import { FaEllipsisH } from "react-icons/fa";
import { findIndex } from 'lodash';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Typography } from '@material-ui/core';
import Select from 'react-select'

const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' }
]

const darkTheme = {
  control: styles => ({ 
    ...styles, 
    backgroundColor: 'black',
    color: 'white',
    overflow: 'visible !important',
    height: '50px',
    maxHeight: '50px',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected
        ? 'darkgray'
        : isFocused
        ? 'gray'
        : null,
      color: 'white',
    };
  },
  singleValue: styles => ({ ...styles, color: 'white' }),
  menu: styles => ({ ...styles, backgroundColor: 'black' }),
};

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  popover: {
  },
  typography: {
    margin: theme.spacing.unit * 2,
  },
  textField: {
    width: '400px',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: '8px',
  },
  confirmButton: {
    width: '80%',
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid #00FF00',
    color: '#00FF00',
    height: 40,
    marginTop: '8px',
    marginBottom: '11px',
    display: 'inline-block',
  },
  deleteButton: {
    width: '6%',
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid red',
    color: 'red',
    height: 40,
    marginTop: '8px',
    marginBottom: '11px',
    display: 'inline-block',
    float: 'right'
  },
  button: {
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid yellow',
    color: 'yellow',
    height: 35,
    marginRight: 3,
    marginLeft: 3,
    marginBottom: 7,
    pointer: 'cursor',
    overflow: 'hidden !important',
    width: 140,
    whiteSpace: 'nowrap',
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: '0.75rem'
  },
  buttonEditable: {
    background: 'transparent',
    borderRadius: 3,
    border: '1px solid white',
    color: 'white',
    height: 35,
    marginRight: 3,
    marginLeft: 3,
    marginBottom: 7,
    pointer: 'cursor',
    overflow: 'hidden !important',
    width: 140,
    whiteSpace: 'nowrap',
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: '0.75rem'
  },
  iconButton: {
    color: 'white',
    fontSize: 16,
  },
  customTextField: {
    height: '7px',
    padding: '6 10px',
    fontSize: '0.7em'
  }
});

class EditTrackItem extends React.Component {

  constructor() {
    super()

    this.state = {
      anchorEl: null,
      description: "",
      uri: "",
      startAt: "",
      volume: 1,
      sliderVolume: 100
    };

    this.saveTrack = this.saveTrack.bind(this);
    this.handleVolumeChange = this.handleVolumeChange.bind(this);
  }

  handleVolumeChange(value) {
    var volume = value / 100;
    this.setState({
      volume: volume,
      sliderVolume: value
    });
  }

  componentDidMount() {
    this.setState({
      description: this.props.link.description,
      uri: this.props.link.uri,
      startAt: this.props.link.startAt,
      volume: this.props.link.volume,
      sliderVolume: this.props.link.volume ? this.props.link.volume * 100 : 100
    });
  }

  deleteTrack() {

    var self = this;

    var playlist = Object.assign({}, this.props.playlist);

    const index = findIndex(playlist.sections[self.props.sectionIndex].links, function (o) { return o.id === self.props.link.id });

    playlist.sections[self.props.sectionIndex].links.splice(index, 1);

    let userId = this.context.user.id;

    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(self.props.playlist.id);

    docRef.update({
      sections: playlist.sections,
    }).then(function () {
      self.handleClose();
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });

  }

  saveTrack() {

    var playlist = Object.assign({}, this.props.playlist);

    var self = this;

    const index = findIndex(playlist.sections[self.props.sectionIndex].links, function (o) { return o.id === self.props.link.id });

    var link = playlist.sections[this.props.sectionIndex].links[index];

    link.description = this.state.description;
    link.uri = this.state.uri;
    link.startAt = parseInt(this.state.startAt);
    link.volume = this.state.volume ? this.state.volume : 1;

    let userId = this.context.user.id;

    var docRef = db.collection("users").doc(userId)
      .collection('playlists').doc(this.props.playlist.id);

    docRef.update({
      sections: playlist.sections,
    }).then(function () {
      self.handleClose();
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  handleClick = event => {
    this.props.onEditClick();
    //this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  static contextType = AuthContext;

  render() {
    const { anchorEl, sliderVolume } = this.state;
    const { classes, link, sortIndex } = this.props;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <Button key={"play_" + link.id} onClick={this.handleClick}
          className={classes.buttonEditable}><span style={{ overflow: 'hidden', width: 100 }}>
            {sortIndex + 1}.&nbsp;&nbsp;{link.description}</span>&nbsp;&nbsp;
            </Button>
        <Popover
          PaperProps={{
            style: {
              backgroundColor: '#080808',
              boxShadow: 'none',
            },
          }}
          className={classes.popover}
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            <TextField
              id="outlined-name"
              label="Beskrivning"
              className={classes.textField}
              style={{ marginTop: '11px' }}
              value={this.state.description}
              onChange={this.handleChange('description')}
              margin="normal"
              variant="outlined"
            /* inputProps={{ className: classes.customTextField }} */
            />
          </div>
          <div>
            <TextField
              id="outlined-name"
              label="Uri"
              className={classes.textField}
              value={this.state.uri}
              onChange={this.handleChange('uri')}
              margin="normal"
              variant="outlined"
            /*  inputProps={{ className: classes.customTextField }} */
            />
          </div>
          <div>
            <TextField
              id="outlined-name"
              label="Startposition (sekunder)"
              className={classes.textField}
              value={this.state.startAt}
              onChange={this.handleChange('startAt')}
              margin="normal"
              variant="outlined"
            /*   inputProps={{ className: classes.customTextField }} */
            />
          </div>
          <div>
            <TextField
              id="outlined-name"
              label="Position"
              className={classes.textField}
              value={this.state.startAt}
              onChange={this.handleChange('startAt')}
              margin="normal"
              variant="outlined"
            /*   inputProps={{ className: classes.customTextField }} */
            />
          </div>
          <div style={{ padding: '10px 20px' }}>
            <Typography variant="body2" style={{ marginBottom: 8 }}>Volym: {sliderVolume}</Typography>
            <Slider
              onChange={this.handleVolumeChange}
              value={sliderVolume}
              defaultValue={sliderVolume}
              trackStyle={{ backgroundColor: 'yellow', height: 5 }}
              handleStyle={{
                borderColor: 'yellow',
                height: 18,
                width: 18,
                marginLeft: -8,
                marginTop: -7,
                backgroundColor: 'black',
              }}
              railStyle={{ backgroundColor: 'yellow', height: 5 }}
            />
          </div>
          <div style={{ padding: '10px 20px' }}>
            <Select options={options} 
                    styles={darkTheme}/>
          </div>
          <div style={{ padding: '10px 20px' }}>
            <Button variant="outlined"
              color="primary"
              className={classes.confirmButton}
              onClick={() => this.saveTrack()}>
              Spara
            </Button>
            <Button variant="outlined"
              color="primary"
              className={classes.deleteButton}
              onClick={() => this.deleteTrack()}>
              X
            </Button>
          </div>
        </Popover>
      </React.Fragment>
    );
  }
}

EditTrackItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditTrackItem);